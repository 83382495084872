import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export class FetchOutletByIdError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: fetchOutletById");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export type IOutlet = { id: string; name: string };

export async function fetchOutletById(outlet: string): Promise<IOutlet> {
  try {
    const response = await http.get(`api/v1/get-outlet-by-id/${outlet}`);
    return response.data;
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchOutletByIdError(error);
  }
}
